import {
  FlagOutlined,
  FolderViewOutlined,
  LockOutlined,
  SendOutlined,
  TrophyOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { HttpStatusCode } from 'axios';
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledSelectOptions } from '../../components/Common/StyledSelect/types';
import { oneAlert } from '../../helpers/nUtils';
import { getURI } from '../../helpers/utils';
import { api } from '../../services/api';
import { services } from '../../services/services';
import {
  BidSupplierViewData,
  ITimeLine,
  LoadingVariablesStatus,
  StepsStatus,
  TermsConditionsData,
  TermsConditionsDetailData,
} from './types';

export const useBidSupplierView = () => {
  const initialSteps: StepsStatus[] = [
    {
      title: 'Open Bid',
      status: 'wait',
      icon: <UnlockOutlined />,
      key: 'OPEN',
    },
    {
      title: 'Round Open',
      status: 'wait',
      icon: <FlagOutlined />,
      key: 'ROUND_OPEN',
    },
    {
      title: 'Quotation Sent',
      status: 'wait',
      icon: <SendOutlined />,
      key: 'QUOTATION_SENT',
    },
    {
      title: 'Bid Closed',
      status: 'wait',
      icon: <LockOutlined />,
      key: 'CLOSED',
    },
    {
      title: 'In Review',
      status: 'wait',
      icon: <FolderViewOutlined />,
      key: 'IN_REVIEW',
    },
    {
      title: 'Result',
      status: 'wait',
      icon: <TrophyOutlined />,
      key: 'CONCLUDED',
    },
  ];

  const { t } = useTranslation();
  const [idBid, setIdBid] = useState<string>('');
  const [bidOptions, setBidOption] = useState<StyledSelectOptions[]>([]);
  const [loading, setIsLoading] = useState<boolean>(true);
  const [steps, setSteps] = useState<Array<StepsStatus>>(initialSteps);
  const [bidData, setBidData] = useState<BidSupplierViewData>({
    rfq: '',
    supplier: '',
    user: '',
    potentialRevenue: '',
    respondDate: '',
    itemsQuotation: [],
  });

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [termsConditon, setTermsConditon] = useState<boolean | null>();
  const [termsConditionData, setTermsConditionData] = useState<string>('');
  const [termsConditionID, setTermsConditionID] = useState<string>('');
  const [termsConditionDetail, setTermsConditionDetail] = useState<TermsConditionsDetailData>();
  const [firstAccess, setFirstAccess] = useState<boolean>(false);
  const [acceptedTerms, setAceptedTerms] = useState<boolean | null>(null);
  const [loadingStatus, setLoadingStatus] = useState<LoadingVariablesStatus>({
    loadingBidID: true,
    loadingStepStatus: true,
    loadingData: true,
    loadingTerms: true,
    loadingTermsStatus: true,
    loadingAcceptedTerms: false,
    loadingDeniedTerms: false,
  });

  const GetSupplierUserName = () => {
    const idToken = localStorage.getItem('idToken');
    if (idToken) {
      const userToken: any = jwtDecode(idToken);
      return {
        user: userToken.nickname,
        supplier: userToken['custom:empresa'],
      };
    }
  };

  const formatIdOptions = (data: Array<any>) => {
    if (data.length > 0) {
      const formattedData: StyledSelectOptions[] = data.map((item) => {
        return {
          label: item.bidName,
          value: item.id,
        };
      });

      return formattedData;
    }
    return [];
  };

  const updateLoadingStatus = (
    loadingVariable: keyof LoadingVariablesStatus,
    newStatus: boolean
  ) => {
    setLoadingStatus((prevState) => ({
      ...prevState,
      [loadingVariable]: newStatus,
    }));
  };

  const getBidId = async () => {
    updateLoadingStatus('loadingBidID', true);
    try {
      const { data, status } = await api.get(
        getURI(`${services.rfq}/bid/filter`, {
          supplier: GetSupplierUserName()?.supplier || '',
          allowSupplierView: true,
        })
      );
      if (status === 200) {
        setBidOption(formatIdOptions(data?.content));
        setIdBid(data?.content[0].id);
      }
      return;
    } catch (error) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
    } finally {
      updateLoadingStatus('loadingBidID', false);
    }
  };

  const updateStatus = (data: ITimeLine[]) => {
    const stepsCopy = initialSteps;
    let currentIndex = 0;

    data.forEach((item) => {
      currentIndex = steps.findIndex((step) => step.key === item.status);
      if (currentIndex > -1) {
        stepsCopy[currentIndex].status = 'finish';
        stepsCopy[currentIndex].description = (
          <div>
            <span>
              {dayjs(item.dateTime).format('MM.DD.YYYY')} - {dayjs(item.dateTime).format('HH:mm')}
            </span>
          </div>
        );
      }
    });

    setSteps(stepsCopy);
  };

  const FetchStatus = async () => {
    if (!idBid) return;
    updateLoadingStatus('loadingStepStatus', true);
    try {
      const { data, status }: { data: Array<ITimeLine>; status: HttpStatusCode } = await api.get(
        getURI(`${services.rfq}/bid/status`, {
          idBid,
          nameSupplier: GetSupplierUserName()?.supplier || '',
        })
      );

      if (status === 200) {
        updateStatus(data);
      }
      return;
    } catch (error) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
    } finally {
      updateLoadingStatus('loadingStepStatus', false);
    }
  };

  const FetchData = async () => {
    if (!idBid) return;
    updateLoadingStatus('loadingData', true);
    try {
      const { data, status }: { data: BidSupplierViewData; status: HttpStatusCode } = await api.get(
        getURI(`${services.rfq}/bid/${idBid}/supplier`)
      );

      if (status === 200) {
        setBidData(data);
      }
      return;
    } catch (error) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
    } finally {
      updateLoadingStatus('loadingData', false);
    }
  };

  const FetchTermsCondition = async () => {
    if (!idBid) return;
    updateLoadingStatus('loadingTerms', true);
    try {
      const { data, status }: { data: TermsConditionsData; status: HttpStatusCode } = await api.get(
        getURI(`${services.rfq}/bid/${idBid}/termsAndConditions`)
      );

      if (status === 200) {
        setTermsConditionID(data.id);
        setTermsConditionData(data.termsAndConditions);
      }
    } catch (error) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
    } finally {
      updateLoadingStatus('loadingTerms', false);
    }
  };

  const FetchTermsConditionStatus = async () => {
    if (!termsConditionID) return;
    updateLoadingStatus('loadingTermsStatus', true);
    try {
      const { data, status }: { data: TermsConditionsDetailData; status: HttpStatusCode } =
        await api.get(
          getURI(`${services.rfq}/bid/termsAndConditions/${termsConditionID}/termDetail/`, {
            supplier: GetSupplierUserName()?.supplier,
          })
        );

      if (status === 200) {
        setTermsConditionDetail(data);
        setAceptedTerms(data.acceptedTermsAndConditions);
        setFirstAccess(false);
        if (data.acceptedTermsAndConditions) {
          setTermsConditon(true);
        }
      }
      if (status === 204) {
        setTermsConditon(false);
        setFirstAccess(true);
      }
    } catch (error) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
    } finally {
      updateLoadingStatus('loadingTermsStatus', false);
    }
  };

  const AcceptedTerms = async () => {
    updateLoadingStatus('loadingAcceptedTerms', true);
    try {
      const { status }: { data: TermsConditionsDetailData; status: HttpStatusCode } = await api.put(
        getURI(`${services.rfq}/bid/${idBid}/termsAndConditions`),
        {
          acceptedTermsAndConditions: true,
          acceptDate: dayjs().toISOString(),
          idBid,
          idTermsAndConditions: termsConditionID,
          userAccept: GetSupplierUserName()?.user,
          supplier: GetSupplierUserName()?.supplier,
          viewedTermsAndConditions: true,
        }
      );

      if (status === 200) {
        setTermsConditon(false);
        setFirstAccess(true);
        FetchTermsConditionStatus();
      }
    } catch (error) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
    } finally {
      updateLoadingStatus('loadingAcceptedTerms', false);
    }
  };

  const DenyTerms = async () => {
    updateLoadingStatus('loadingDeniedTerms', true);
    try {
      const { status }: { data: TermsConditionsDetailData; status: HttpStatusCode } = await api.put(
        getURI(`${services.rfq}/bid/${idBid}/termsAndConditions`),
        {
          acceptedTermsAndConditions: false,
          rejectDate: dayjs().toISOString(),
          idBid,
          idTermsAndConditions: termsConditionID,
          userReject: GetSupplierUserName()?.user,
          supplier: GetSupplierUserName()?.supplier,
          viewedTermsAndConditions: true,
        }
      );

      if (status === 200) {
        setTermsConditon(false);
        setFirstAccess(true);
        FetchTermsConditionStatus();
      }
    } catch (error) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
    } finally {
      updateLoadingStatus('loadingDeniedTerms', false);
    }
  };
  const Review = () => {
    setFirstAccess(true);
  };

  useEffect(() => {
    FetchTermsCondition();
  }, [idBid]);

  useEffect(() => {
    FetchTermsConditionStatus();
  }, [termsConditionID]);

  useEffect(() => {
    Object.values(loadingStatus).some((item) => item === true)
      ? setIsLoading(true)
      : setIsLoading(false);
  }, [loadingStatus]);

  return {
    steps,
    bidData,
    bidOptions,
    idBid,
    loading,
    termsConditon,
    openModal,
    termsConditionData,
    termsConditionID,
    firstAccess,
    termsConditionDetail,
    acceptedTerms,
    FetchTermsCondition,
    AcceptedTerms,
    DenyTerms,
    FetchStatus,
    updateStatus,
    getBidId,
    FetchData,
    setIdBid,
    GetSupplierUserName,
    setOpenModal,
    Review,
  };
};
