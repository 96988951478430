/* eslint-disable no-undefined */
import {
  FlagOutlined,
  FundViewOutlined,
  LockOutlined,
  TrophyOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { TabsProps } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { oneAlert } from '../../helpers/nUtils';
import { getURI } from '../../helpers/utils';
import { api } from '../../services/api';
import { services } from '../../services/services';
import {
  IAltGroupDetails,
  IBidInformations,
  ICommentModal,
  IError,
  ISelectedAltGroup,
  IStepsStatus,
  ITimeLine,
} from './types';

export function useViewRFQ() {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [bidId, setBidId] = useState<string>('');
  const [loading, setIsLoading] = useState<boolean>(false);

  //overview
  const [bidInformations, setbidInformations] = useState<IBidInformations>();
  const initialSteps: IStepsStatus[] = [
    {
      title: t('pages.viewRfq.steps.openBid'),
      icon: <UnlockOutlined />,
      key: 'OPEN',
    },
    {
      title: t('pages.viewRfq.steps.roundOpen'),
      icon: <FlagOutlined />,
      key: 'ROUND_OPEN',
    },
    {
      title: t('pages.viewRfq.steps.bidClosed'),
      icon: <LockOutlined />,
      key: 'CLOSED',
    },
    {
      title: t('pages.viewRfq.steps.inReview'),
      icon: <FundViewOutlined />,
      key: 'IN_REVIEW',
    },
    {
      title: t('pages.viewRfq.steps.result'),
      icon: <TrophyOutlined />,
      key: 'CONCLUDED',
    },
  ];
  const [steps, setSteps] = useState<IStepsStatus[]>(initialSteps);

  //alt group detail
  const [isLoadingDetails, setIsLoadingDetails] = useState<boolean>(false);
  const [altGroupDetail, setAltGroupDetail] = useState<IAltGroupDetails>();
  const [tabProjects, setTabProjects] = useState<TabsProps['items']>();
  const [selectedAltGroup, setSelectedAltGroup] = useState<ISelectedAltGroup>({
    altGroup: '',
    category: '',
  });
  const [selectedProject, setSelectedProject] = useState<string>();
  const [isOpenCommentsModal, setIsOpenCommentsModal] = useState<ICommentModal>({
    isOpen: false,
    comments: undefined,
  });

  const updateStatus = (data: ITimeLine[]) => {
    const stepsCopy = initialSteps;
    let currentIndex = 0;

    data.forEach((item) => {
      currentIndex = steps.findIndex((step) => step.key === item.status);
      if (currentIndex > -1) {
        stepsCopy[currentIndex].status = 'finish';
        stepsCopy[currentIndex].description = (
          <div>
            <span>
              {dayjs(item.dateTime).format('MM.DD.YYYY')} - {dayjs(item.dateTime).format('HH:mm')}
            </span>
          </div>
        );
      }
    });

    setSteps(stepsCopy);
  };

  const fetchStatus = async () => {
    if (!bidId) return;
    setIsLoading(true);
    try {
      const { data, status } = await api.get(
        getURI(`${services.rfq}/bid/status`, { idBid: bidId })
      );

      if (status === 200) {
        updateStatus(data);
      }
      return;
    } catch (error) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const formatProjectTabs = (projects: string[]) => {
    setTabProjects(
      projects.map((project) => {
        return {
          key: project,
          label: project,
        };
      })
    );
  };

  const getSummaryInformations = async () => {
    try {
      setIsLoading(true);
      const { status, data } = await api.get(`${services.rfq}/bid/admin/${bidId}`);
      if (status === 200) {
        setbidInformations(data);
      } else setbidInformations(undefined);
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse.messageDetail || t('toast.errorOnList'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectAltGroup = (altGroup: string, category: string) => {
    if (altGroup === selectedAltGroup.altGroup && category === selectedAltGroup.category) {
      setSelectedAltGroup({
        altGroup: '',
        category: '',
      });
    } else {
      setSelectedAltGroup({
        altGroup,
        category,
      });
    }
    setSelectedProject(undefined);
    setTabProjects(undefined);
  };

  const fetchAltGroup = async () => {
    try {
      setIsLoadingDetails(true);
      const { status, data } = await api.get(
        getURI(`${services.rfq}/bid/admin/${bidId}/detail`, {
          ...selectedAltGroup,
          project: selectedProject,
        })
      );
      if (status === 200) {
        if (!selectedProject) formatProjectTabs(data.projects);
        setSelectedProject(data.currentProject);
        setAltGroupDetail(data);
      }
      if (status === 204) {
        setTabProjects(undefined);
        setSelectedProject(undefined);
        setAltGroupDetail(undefined);
      }
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse.messageDetail || t('toast.errorOnList'),
      });
    } finally {
      setIsLoadingDetails(false);
    }
  };

  useEffect(() => {
    if (selectedProject) fetchAltGroup();
  }, [selectedProject]);

  useEffect(() => {
    if (selectedAltGroup.altGroup && selectedAltGroup.category) fetchAltGroup();
  }, [selectedAltGroup]);

  useEffect(() => {
    if (bidId) {
      Promise.all([getSummaryInformations(), fetchStatus()]);
    }
  }, [bidId]);

  useEffect(() => {
    if (search) {
      const id = search.split('=')[1];
      setBidId(id);
    }
  }, []);

  return {
    bidId,
    bidInformations,
    loading,
    steps,
    isOpenCommentsModal,
    setIsOpenCommentsModal,
    handleSelectAltGroup,
    selectedAltGroup,
    tabProjects,
    isLoadingDetails,
    altGroupDetail,
    setSelectedProject,
    selectedProject,
  };
}
